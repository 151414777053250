<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Site changes</h2>
          <p>
            As your site runs on WordPress we're able to auto-detect and log key
            changes made to your website and hosting environment. Here you can
            see a snapshot of the current "state" as well as a log of all recent
            changes. This insight can prove invaluable when debugging site
            issues.
          </p>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="box">
              <b-tabs v-model="activeTab">
                <b-tab-item label="Change log">
                  <site-changes :site-id="siteId" />
                </b-tab-item>
                <b-tab-item label="Current state">
                  <site-latest-change :site-id="siteId" />
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteChanges",
  components: {
    "site-latest-change": () =>
      import("@shared/sites/_siteChangeLatestShapshot.vue"),
    "site-changes": () => import("@shared/sites/_siteChangesView")
  },
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      activeTab: 0
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep.b-tabs {
  .tab-content {
    padding: 1.5rem 0;
  }
}
</style>
